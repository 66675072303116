import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const Left = styled.p`
  text-align: left;
`;
const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="Acerca de Carol" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <Left>
                  Carol Espinaza es madre, floridana, periodista y licenciada en comunicación. Ha
                  trabajado por varios años en actividades comunitarias organizando congresos de
                  capacitación y actividades para niños y sus familias de hasta 15 mil personas.
                </Left>
                <Left>
                  Por más de diez años ha trabajado a cargo de equipos de voluntarios; también tiene
                  experiencia en la administración municipal, como jefa de relaciones públicas y
                  comunicación y a cargo de la oficina de asuntos religiosos.
                </Left>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
