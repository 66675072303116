import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';

import PortfolioContext from '../../context/context';
import Carol from './Carol';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <Container style={{ padding: '0 !important' }}>
      <Fade left={isDesktop} bottom={isMobile} duration={500} delay={250} distance="30px">
        <Carol />
      </Fade>

      <Fade left={isDesktop} bottom={isMobile} duration={500} delay={500} distance="30px">
        <p className="hero-cta" style={{ marginTop: '1em' }}>
          <span className="cta-btn cta-btn--hero">
            <Link to="about" smooth duration={500}>
              {cta || '> más información'}
            </Link>
          </span>
        </p>
      </Fade>
    </Container>
  );
};

export default Header;
