import React from 'react';
import ReactPlayer from 'react-player';
import { Button } from 'react-bootstrap';

function App() {
  return (
    <div style={{ marginBottom: '3em' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2em' }} v>
        <ReactPlayer url="https://www.youtube.com/watch?v=eBMvb2Fj0ak" />
      </div>
      <Button
        style={{ fontSize: '1.5em', backgroundColor: 'red', color: 'white' }}
        variant="danger"
        size="lg"
        href="https://www.youtube.com/channel/UC-7IRCbI9JHbVRw8Nfusxtg"
      >
        Ir al canal de youtube
      </Button>
    </div>
  );
}

export default App;
