import styled from 'styled-components';

const Carol = styled.div`
  background-image: url('/carol.png');

  /* Set a specific height */
  height: 75vh;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export default Carol;
